html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body{
  background-color: var(--bg);
}
.code-fission {
  margin-left: -0.063rem;
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  flex-shrink: 0;
  debug_commit: bf4bc93;
  white-space: nowrap;
}

.about-us,
.contact,
.services {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 4.75rem;
}
.about-us,
.contact {
  min-width: 4.563rem;
}
.about-us {
  min-width: 5.188rem;
  white-space: nowrap;
}
.nav-frame,
.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
  text-align: left;
  color: var(--wei);
}
.nav-frame {
  margin: 0;
  justify-content: space-between;
  gap: 1.25rem;
  font-size: var(--bodytext-size);
  font-family: var(--bodytext);
}
.header {
  align-self: stretch;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 0 0.125rem 1.125rem;
  box-sizing: border-box;
  gap: 6.687rem;
  z-index: 99;
  position: sticky;
  font-size: 1.875rem;
  font-family: var(--font-black-han-sans);
  width: 100%;
  text-align: left;
  color: var(--wei);
  font-family: var(--font-black-han-sans);
}
.code-fission1,
.simplifying-complexity,
.unleashing-potential {
  margin: 0;
}
.code-fission-simplifying-container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 6.25rem;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
}
.seeMore {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 4.25rem;
  color: var(--bodytext);
  text-decoration: none;
}

.iconnavigationarrow-downward-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.312rem 0 0;
}
.seeMore-parent,
.frame-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.seeMore-parent {
  justify-content: flex-start;
  gap: 0.375rem;
}

.seeMore img {
  rotate: 90deg;
}

.frame-wrapper {
  align-self: stretch;
  justify-content: center;
  padding: 0 1.25rem;
  text-align: left;
  font-size: var(--bodytext-size);
  font-family: var(--font-roboto-condensed);
}
.code-fission-simplifying-compl-parent {
  width: 53.563rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  max-width: 100%;
}
.splash-main-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  font-size: var(--header-size);
  color: var(--wei);
  font-family: var(--header);
  padding-top: 2em;
}
.splash,
.splash-main {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.splash-main {
  flex: 1;
  flex-direction: column;
  padding: 4rem 5rem 27.687rem;
  box-sizing: border-box;
  gap: 18.25rem;
  
  background: url(./public/header/header_3x.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
}
.splash {
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #0b1d26 , #0b1d26 49.5%);
  
  overflow: hidden;
  flex-direction: row;
  line-height: normal;
  letter-spacing: normal;
}
.icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
  object-fit: cover;
  max-width: 40rem;
  max-height: 40rem;
}

.equipment,
.where-you-go {
  position: absolute;
  top: 0;
  font-weight: 500;
}
.equipment {
  left: 0;
  line-height: 15rem;
  opacity: 0.1;
}
.where-you-go {
  left: 6rem;
  letter-spacing: 6px;
  text-transform: uppercase;
}
.line {
  position: absolute;
  top: 0.625rem;
  left: 0;
  background-color: var(--accent);
  width: 4.5rem;
  height: 0.125rem;
}
.tagline {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.at-code-fission,
.expert-guidance {
  position: relative;
  font-weight: 500;
  font-family: var(--bodytext);
  color: var(--wei);
}
.expert-guidance {
  margin: 0;
  align-self: stretch;
  font-size: var(--sub-headers-size);
}
.at-code-fission {
  max-width: 39.5rem;
  display: inline-block;
}

.equipment1 {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  text-decoration: none;
  color: var(--bodytext);
}

.equipment1::after{
  content: '\2192'; 
  margin-left: 5px;
}
.iconnavigationarrow-downward {
  object-fit: contain;
  width: 1rem;
  position: relative;
  object-fit: cover;
}
.more {
  width: 7.938rem;
  position: relative;
  height: 1.313rem;
  cursor: pointer;
}
.content1 {
  position: relative;
  height: 75.24%;
  width: 100%;
  top: 24.76%;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xl);
}
.frame2 {
  font-size: var(--bodytext-size);
  color: var(--accent);
  padding-left: 8em;
  padding-top: 8em;
}
.frame1 {
  position: relative;
  flex-shrink: 0;
}
.advisory,
.frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame {
  flex: 1;
  gap: var(--gap-71xl);
}
.advisory {
  text-align: left;
  font-size: var(--font-size-221xl);
  color: var(--wei);
  font-family: var(--font-roboto-condensed);
}
.frame4,
.icon1 {
  position: relative;
  overflow: hidden;
}
.icon1 {
  align-self: stretch;
  flex: 1;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.contact-us {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 6.25rem;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  font-family: inherit;
}
.at-code-fission4 {
  height: 18.125rem;
  width: 31.313rem;
  position: relative;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}

.frame12 {
  width: 100%;
  position: relative;
  height: 15.375rem;
  flex-shrink: 0;
}
.connect-with-us {
  color: var(--bg);
}
.span {
  color: var(--wei);
}
.connect-with-us-container {
  position: absolute;
  top: 0;
  left: 0.938rem;
  font-size: var(--bodytext-size);
  font-weight: 500;
  font-family: var(--bodytext);
  text-align: left;
}
.connect-with-us-wrapper {
  border: 0;
  padding: 0;
  background-color: var(--accent);
  width: 11.563rem;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-8xs);
  height: 1.375rem;
  overflow: hidden;
  flex-shrink: 0;
}
.form {
  margin: 0;
  width: 25rem;
  height: 18.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
}
.at-code-fission-we-are-commit-parent,
.contact {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2.625rem;
  font-size: var(--bodytext-size);
  font-family: var(--bodytext);
}
.contact {
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
  text-align: left;
  font-size: var(--header-size);
  color: var(--wei);
  font-family: var(--header);
}
.code-fission,
.simplifying-complexity-unleas {
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.code-fission {
  margin: 0;
  width: 24.813rem;
  font-size: var(--sub-headers-size);
  font-family: inherit;
}
.simplifying-complexity-unleas {
  width: 27.375rem;
}
.connectUsSubmit {
  gap: 1.875rem;
  text-align: left;
  color: var(--accent);
  font-weight: 500;
 
}
.email {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--wei);
  width:5em;
  display: block;
  margin: auto;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.links {
  text-decoration: none;
  position: relative;
  font-weight: 200;
  text-decoration: none;
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 1.25rem;
  gap: 1.875rem;
  text-align: left;
  color: var(--accent);
}
.email-text{
  color: var(--wei);
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.code-fission-parent,
.content,
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.footer {
  text-align: center;
  font-size: var(--bodytext-size);
  color: var(--wei);
  font-family: var(--bodytext);
}
.content {
  position: relative;
  padding: 10%;
  gap: 12.5rem;
}

.code-fission1,
.frame15 {
  margin: 0;
  position: absolute;
}
.frame15 {
  top: -0.187rem;
  left: calc(50% - 560px);
  width: 70rem;
  height: 1.625rem;
  text-align: left;
  font-size: var(--bodytext-size);
  color: var(--wei);
  font-family: var(--bodytext);
}
.code-fission1 {
  top: calc(50% - 19px);
  left: -0.062rem;
  font-size: inherit;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
}
.logo {
  top: calc(50% - 23px);
  left: 0.125rem;
  width: 13.188rem;
  height: 2.875rem;
}
.frame14,
.header {
  position: absolute;
}

.simplifying-complexity {
  margin: 0;
}
.code-fission-simplifying-container {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 6.25rem;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.iconnavigationarrow-downward4 {
  height: 1rem;
  object-fit: cover;
  rotate: 90deg;
}
.equipment-parent {
  position: relative;
  height: 1.813rem;
  cursor: pointer;
  text-align: left;
  font-size: var(--bodytext-size);
  font-family: var(--font-roboto-condensed);
}
.content5,
.frame13 {
  position: absolute;
  overflow: hidden;
}
.content5 {
  top: 15.063rem;
  left: calc(50% - 494px);
  width: 61.688rem;
  height: 33.438rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}
.frame13 {
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(11, 29, 38, 0.23), #0b1d26 49.5%);
  width: 120rem;
  height: 75rem;
}
.home {
  width: 100%;
  position: relative;
  box-shadow: var(--shadow);
  background-color: var(--bg);
  text-align: center;
  font-size: var(--header-size);
  color: var(--wei);
  font-family: var(--header);
}
@media screen and (max-width: 1200px) {
.frame {
  display: block;
}
.icon {
  display: none;
}
.splash-main {
  gap: 9.125rem;
  padding: 2.625rem 2.5rem 18rem;
  box-sizing: border-box;
}
}
@media screen and (max-width: 960px) {
  .at-code-fission,
  .icon {
    align-self: stretch;
    flex: 1;
  }
  .icon {
    height: auto;
  }
  .at-code-fission {
    width: auto;
  }
  .frame4,
  .icon1 {
    align-self: stretch;
    height: auto;
  }
  .frame4 {
    max-width: 50%;
  }
  .at-code-fission4 {
    font-weight: 100;
    font-family: var(--bodytext);
    font-size: var(--bodytext-size);
    text-align: center;
    height: auto;
    width: auto;
  }
  .form {
    flex: 1;
    justify-content: flex-start;
    padding-top: 2em;
  }
  .at-code-fission-we-are-commit-parent{
    display: inline;
  }
  .header {
    gap: 3.313rem;
  }
  .code-fission-simplifying-container {
    font-size: 3.688rem;
    line-height: 5rem;
  }
  .splash-main {
    gap: 4.563rem;
    padding-top: 1.688rem;
    padding-bottom: 11.688rem;
    box-sizing: border-box;
  }
  .contact a {
    padding-top: 2em;
  }
}
@media screen and (max-width: 420px) {
  .icon {
    display: none;
  }
  .header {
    gap: 1.688rem;
    text-align: center;
  }
  .code-fission-simplifying-container {
    font-size: 2.75rem;
    line-height: 3.75rem;
  }
  .frame2 {
    padding: 0 0 0 0;
  }
  .nav-frame, .header{
    display: block;
    text-align: center;
  }
  .content {
    gap:2em;
  }
  .tagline {
    margin-bottom: 2em;
  }
  .simplifying-complexity-unleas {
    width: auto;
  }
  .links {
    padding: 0 1.25em 0 0;
    text-align: center;
  }
}