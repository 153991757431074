html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: var(--bg);
}

.code-fission {
  font-size: inherit;
  letter-spacing: .01em;
  text-transform: capitalize;
  debug_commit: bf4bc93;
  white-space: nowrap;
  flex-shrink: 0;
  margin: 0;
  font-family: inherit;
  font-weight: 400;
  position: relative;
}

.about-us, .contact, .services {
  min-width: 4.75rem;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.about-us, .contact {
  min-width: 4.563rem;
}

.about-us {
  white-space: nowrap;
  min-width: 5.188rem;
}

.nav-frame, .header {
  text-align: left;
  color: var(--wei);
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
  display: flex;
}

.nav-frame {
  font-size: var(--bodytext-size);
  font-family: var(--bodytext);
  justify-content: space-between;
  gap: 1.25rem;
  margin: 0;
}

.header {
  box-sizing: border-box;
  z-index: 99;
  font-size: 1.875rem;
  font-family: var(--font-black-han-sans);
  text-align: left;
  color: var(--wei);
  font-family: var(--font-black-han-sans);
  flex-shrink: 0;
  justify-content: flex-start;
  align-self: stretch;
  gap: 6.687rem;
  width: 100%;
  padding: 0 .125rem 1.125rem;
  position: sticky;
  overflow: hidden;
}

.code-fission1, .simplifying-complexity, .unleashing-potential {
  margin: 0;
}

.code-fission-simplifying-container {
  font-size: inherit;
  text-transform: capitalize;
  align-self: stretch;
  margin: 0;
  font-family: inherit;
  font-weight: 700;
  line-height: 6.25rem;
  position: relative;
}

.seeMore {
  color: var(--bodytext);
  min-width: 4.25rem;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.iconnavigationarrow-downward-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: .312rem 0 0;
  display: flex;
}

.seeMore-parent, .frame-wrapper {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.seeMore-parent {
  justify-content: flex-start;
  gap: .375rem;
}

.seeMore img {
  rotate: 90deg;
}

.frame-wrapper {
  text-align: left;
  font-size: var(--bodytext-size);
  font-family: var(--font-roboto-condensed);
  justify-content: center;
  align-self: stretch;
  padding: 0 1.25rem;
}

.code-fission-simplifying-compl-parent {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  width: 53.563rem;
  max-width: 100%;
  display: flex;
}

.splash-main-inner {
  text-align: center;
  font-size: var(--header-size);
  color: var(--wei);
  font-family: var(--header);
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
  padding-top: 2em;
  display: flex;
}

.splash, .splash-main {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.splash-main {
  box-sizing: border-box;
  background: url("header_3x.b2bba01c.webp") top / cover no-repeat;
  flex-direction: column;
  flex: 1;
  gap: 18.25rem;
  max-width: 100%;
  padding: 4rem 5rem 27.687rem;
}

.splash {
  letter-spacing: normal;
  background: linear-gradient(#0b1d26, #0b1d26 49.5%);
  flex-direction: row;
  width: 100%;
  line-height: normal;
  position: relative;
  overflow: hidden;
}

.icon {
  object-fit: cover;
  flex: 1;
  align-self: stretch;
  max-width: 40rem;
  max-height: 40rem;
  position: relative;
  overflow: hidden;
}

.equipment, .where-you-go {
  font-weight: 500;
  position: absolute;
  top: 0;
}

.equipment {
  opacity: .1;
  line-height: 15rem;
  left: 0;
}

.where-you-go {
  letter-spacing: 6px;
  text-transform: uppercase;
  left: 6rem;
}

.line {
  background-color: var(--accent);
  width: 4.5rem;
  height: .125rem;
  position: absolute;
  top: .625rem;
  left: 0;
}

.tagline {
  flex: 1;
  align-self: stretch;
  position: relative;
}

.at-code-fission, .expert-guidance {
  font-weight: 500;
  font-family: var(--bodytext);
  color: var(--wei);
  position: relative;
}

.expert-guidance {
  font-size: var(--sub-headers-size);
  align-self: stretch;
  margin: 0;
}

.at-code-fission {
  max-width: 39.5rem;
  display: inline-block;
}

.equipment1 {
  color: var(--bodytext);
  font-weight: 500;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
}

.equipment1:after {
  content: "→";
  margin-left: 5px;
}

.iconnavigationarrow-downward {
  object-fit: contain;
  object-fit: cover;
  width: 1rem;
  position: relative;
}

.more {
  cursor: pointer;
  width: 7.938rem;
  height: 1.313rem;
  position: relative;
}

.content1 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-8xl);
  flex-direction: column;
  width: 100%;
  height: 75.24%;
  display: flex;
  position: relative;
  inset: 24.76% 0 0;
  overflow: hidden;
}

.frame2 {
  font-size: var(--bodytext-size);
  color: var(--accent);
  padding-top: 8em;
  padding-left: 8em;
}

.frame1 {
  flex-shrink: 0;
  position: relative;
}

.advisory, .frame {
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.frame {
  gap: var(--gap-71xl);
  flex: 1;
}

.advisory {
  text-align: left;
  font-size: var(--font-size-221xl);
  color: var(--wei);
  font-family: var(--font-roboto-condensed);
}

.frame4, .icon1 {
  position: relative;
  overflow: hidden;
}

.icon1 {
  object-fit: cover;
  flex: 1;
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
}

.contact-us {
  font-size: inherit;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  font-family: inherit;
  font-weight: 700;
  line-height: 6.25rem;
  position: relative;
}

.at-code-fission4 {
  flex-shrink: 0;
  width: 31.313rem;
  height: 18.125rem;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.frame12 {
  flex-shrink: 0;
  width: 100%;
  height: 15.375rem;
  position: relative;
}

.connect-with-us {
  color: var(--bg);
}

.span {
  color: var(--wei);
}

.connect-with-us-container {
  font-size: var(--bodytext-size);
  font-weight: 500;
  font-family: var(--bodytext);
  text-align: left;
  position: absolute;
  top: 0;
  left: .938rem;
}

.connect-with-us-wrapper {
  background-color: var(--accent);
  border-radius: var(--br-8xs);
  border: 0;
  flex-shrink: 0;
  width: 11.563rem;
  height: 1.375rem;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px #00000040;
}

.form {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  width: 25rem;
  height: 18.125rem;
  margin: 0;
  display: flex;
}

.at-code-fission-we-are-commit-parent, .contact {
  font-size: var(--bodytext-size);
  font-family: var(--bodytext);
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2.625rem;
  display: flex;
}

.contact {
  text-align: left;
  font-size: var(--header-size);
  color: var(--wei);
  font-family: var(--header);
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: .625rem;
  overflow: hidden;
}

.code-fission, .simplifying-complexity-unleas {
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.code-fission {
  font-size: var(--sub-headers-size);
  width: 24.813rem;
  margin: 0;
  font-family: inherit;
}

.simplifying-complexity-unleas {
  width: 27.375rem;
}

.connectUsSubmit {
  text-align: left;
  color: var(--accent);
  gap: 1.875rem;
  font-weight: 500;
}

.email {
  text-align: center;
  color: var(--wei);
  filter: invert() sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  justify-content: center;
  align-items: center;
  width: 5em;
  margin: auto;
  display: block;
}

.links {
  text-align: left;
  color: var(--accent);
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 1.875rem;
  padding: 1.25rem;
  font-weight: 200;
  text-decoration: none;
  position: relative;
}

.email-text {
  color: var(--wei);
  text-align: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.code-fission-parent, .content, .footer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.footer {
  text-align: center;
  font-size: var(--bodytext-size);
  color: var(--wei);
  font-family: var(--bodytext);
}

.content {
  gap: 12.5rem;
  padding: 10%;
  position: relative;
}

.code-fission1, .frame15 {
  margin: 0;
  position: absolute;
}

.frame15 {
  text-align: left;
  font-size: var(--bodytext-size);
  color: var(--wei);
  font-family: var(--bodytext);
  width: 70rem;
  height: 1.625rem;
  top: -.187rem;
  left: calc(50% - 560px);
}

.code-fission1 {
  font-size: inherit;
  letter-spacing: .01em;
  text-transform: capitalize;
  font-family: inherit;
  font-weight: 400;
  top: calc(50% - 19px);
  left: -.062rem;
}

.logo {
  width: 13.188rem;
  height: 2.875rem;
  top: calc(50% - 23px);
  left: .125rem;
}

.frame14, .header {
  position: absolute;
}

.simplifying-complexity {
  margin: 0;
}

.code-fission-simplifying-container {
  font-size: inherit;
  text-transform: capitalize;
  margin: 0;
  font-family: inherit;
  font-weight: 700;
  line-height: 6.25rem;
  display: inline-block;
  position: relative;
}

.iconnavigationarrow-downward4 {
  object-fit: cover;
  height: 1rem;
  rotate: 90deg;
}

.equipment-parent {
  cursor: pointer;
  text-align: left;
  font-size: var(--bodytext-size);
  font-family: var(--font-roboto-condensed);
  height: 1.813rem;
  position: relative;
}

.content5, .frame13 {
  position: absolute;
  overflow: hidden;
}

.content5 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  width: 61.688rem;
  height: 33.438rem;
  display: flex;
  top: 15.063rem;
  left: calc(50% - 494px);
}

.frame13 {
  background: linear-gradient(#0b1d263b, #0b1d26 49.5%);
  width: 120rem;
  height: 75rem;
  top: 0;
  left: 0;
}

.home {
  box-shadow: var(--shadow);
  background-color: var(--bg);
  text-align: center;
  font-size: var(--header-size);
  color: var(--wei);
  font-family: var(--header);
  width: 100%;
  position: relative;
}

@media screen and (width <= 1200px) {
  .frame {
    display: block;
  }

  .icon {
    display: none;
  }

  .splash-main {
    box-sizing: border-box;
    gap: 9.125rem;
    padding: 2.625rem 2.5rem 18rem;
  }
}

@media screen and (width <= 960px) {
  .at-code-fission, .icon {
    flex: 1;
    align-self: stretch;
  }

  .icon {
    height: auto;
  }

  .at-code-fission {
    width: auto;
  }

  .frame4, .icon1 {
    align-self: stretch;
    height: auto;
  }

  .frame4 {
    max-width: 50%;
  }

  .at-code-fission4 {
    font-weight: 100;
    font-family: var(--bodytext);
    font-size: var(--bodytext-size);
    text-align: center;
    width: auto;
    height: auto;
  }

  .form {
    flex: 1;
    justify-content: flex-start;
    padding-top: 2em;
  }

  .at-code-fission-we-are-commit-parent {
    display: inline;
  }

  .header {
    gap: 3.313rem;
  }

  .code-fission-simplifying-container {
    font-size: 3.688rem;
    line-height: 5rem;
  }

  .splash-main {
    box-sizing: border-box;
    gap: 4.563rem;
    padding-top: 1.688rem;
    padding-bottom: 11.688rem;
  }

  .contact a {
    padding-top: 2em;
  }
}

@media screen and (width <= 420px) {
  .icon {
    display: none;
  }

  .header {
    text-align: center;
    gap: 1.688rem;
  }

  .code-fission-simplifying-container {
    font-size: 2.75rem;
    line-height: 3.75rem;
  }

  .frame2 {
    padding: 0;
  }

  .nav-frame, .header {
    text-align: center;
    display: block;
  }

  .content {
    gap: 2em;
  }

  .tagline {
    margin-bottom: 2em;
  }

  .simplifying-complexity-unleas {
    width: auto;
  }

  .links {
    text-align: center;
    padding: 0 1.25em 0 0;
  }
}
/*# sourceMappingURL=index.eedfdf2b.css.map */
